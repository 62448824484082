<template>
  <el-row class="affix-images zt-block" v-if="affix && affix.attachment">
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label">营业执照</el-col>
        <el-col :span="20">
          <div v-if="affix.attachment.license" class="imgs">
            <img
              :src="imgUrl + affix.attachment.license"
              style="width: 112px; height: 156px; margin-right: 20px"
              @click="toPreview(affix.attachment.license)"
            />
          </div>
          <No-Data v-else></No-Data>
        </el-col>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label">法定代表人信息</el-col>
        <el-col :span="20">
          <div v-if="affix.attachment.idCard" class="imgs">
            <img
              :src="imgUrl + affix.attachment.idCard.pros"
              style="width: 179px; height: 113px; margin-right: 20px"
              @click="toPreview(affix.attachment.idCard.pros)"
            />
            <img
              :src="imgUrl + affix.attachment.idCard.cons"
              style="width: 179px; height: 113px; margin-right: 20px"
              @click="toPreview(affix.attachment.idCard.cons)"
            />
          </div>
          <No-Data v-else></No-Data>
        </el-col>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">设计公司</el-col>
        <el-col :span="22" style="height:40px;line-height:40px">
          {{ affix.desginCompName }}
        </el-col>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">设计合同</el-col>
        <el-col
          :span="22"
          v-if="affix.attachment.contracts && affix.attachment.contracts.length"
        >
          <div v-for="(contract,idx) in affix.attachment.contracts" :key="contract">
            <el-button type="text" @click="toPreview(contract)">{{
              `设计合同${idx + 1}.${
                contract.split(".") && contract.split(".")[1]
                  ? contract.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button>
          </div>
        </el-col>
        <No-Data v-else></No-Data>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">渲染图</el-col>
        <el-col
          :span="22"
          v-if="affix.attachment.renders && affix.attachment.renders.length"
        >
          <div v-for="(drawing) in affix.attachment.renders" :key="drawing">
            <el-button type="text" @click="toPreview(drawing)">{{
              `${drawing.split("/")[drawing.split("/").length-2]}.${
                drawing.split(".") && drawing.split(".")[1]
                  ? drawing.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button>
          </div>
        </el-col>
        <No-Data v-else></No-Data>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">设计图纸</el-col>
        <el-col
          :span="22"
          v-if="affix.attachment.drawings && affix.attachment.drawings.length"
        >
          <div v-for="(drawing) in affix.attachment.drawings" :key="drawing">
            <!-- <el-button type="text" @click="toPreview(drawing)">{{
              `设计图纸${idx + 1}.${
                drawing.split(".") && drawing.split(".")[1]
                  ? drawing.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button> -->
            <el-button type="text" @click="toPreview(drawing)">{{
              `${drawing.split("/")[drawing.split("/").length-2]}.${
                drawing.split(".") && drawing.split(".")[1]
                  ? drawing.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button>
          </div>
        </el-col>
        <No-Data v-else></No-Data>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">税务发票</el-col>
        <el-col
          :span="22"
          v-if="affix.attachment.receipts && affix.attachment.receipts.length"
        >
          <div
            v-for="(receipt, idx) in affix.attachment.receipts"
            :key="receipt"
          >
            <el-button type="text" @click="toPreview(receipt)">{{
              `税务发票${idx + 1}.${
                receipt.split(".") && receipt.split(".")[1]
                  ? receipt.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button>
          </div>
        </el-col>
        <No-Data v-else></No-Data>
      </el-row>
    </el-col>
    <el-col class="affix-images-item">
      <el-row type="flex" align="top">
        <el-col :span="2" class="label flex flex-align-center">付款凭证</el-col>
        <el-col
          :span="22"
          v-if="affix.attachment.vouchers && affix.attachment.vouchers.length"
        >
          <div v-for="(voucher,idx) in affix.attachment.vouchers" :key="voucher">
            <el-button type="text" @click="toPreview(voucher)">{{
              `付款凭证${idx + 1}.${
                voucher.split(".") && voucher.split(".")[1]
                  ? voucher.split(".")[1]
                  : ".pdf"
              }`
            }}</el-button>
          </div>
        </el-col>
        <No-Data v-else></No-Data>
      </el-row>
    </el-col>
    <!-- 图片预览弹窗 -->
    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      width="40%"
      :title="popTitle"
      :visible.sync="dialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-row>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
export default {
  props: {
    affix: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    imgUrl() {
      return IMG_URL_PRE;
    },
  },
  data() {
    return {
      popTitle: "",
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  methods: {
    toPreview(url) {
      window.open(this.imgUrl + url);
    },
  },
};
</script>
<style lang="less" scoped>
.affix-images {
  padding: 40px;

  &-item {
    margin-bottom: 10px;
  }

  .label {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: #3b3b3b;
  }

  .imgs {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>