<template>
  <div class="wrapper zt-block flex flex-pack-center" v-loading="loading">
    <el-row class="apply-info">
      <el-col class="apply-info-title"> 南康工业(家具)设计奖补申请表</el-col>
      <el-col>
        <el-row type="flex" class="header" align="middle">
          <el-col :span="12">
            <el-row type="flex" align="middle">
              <el-col>
                <span class="label" style="margin-left: 50px"
                  >申请单位( 盖章 )：</span
                >
              </el-col>

              <el-col v-if="showApplyCompStamp">
                <canvas id="applyComp" width="156" height="156"></canvas>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <span class="label">申请时间：</span>
            <span
              style="
                text-align: left;
                display: inline-block;
                min-width: 150px;
                font-size: 14px;
                font-weight: 400;
                color: #808080;
              "
              >{{
                applyInfo && applyInfo.createdAt | moment("YYYY/MM/DD")
              }}</span
            >
          </el-col>
        </el-row>
      </el-col>
      <el-col class="apply-info-table">
        <div class="flex">
          <div class="cus-blank"></div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="label border"
              >
                企业名称<br />(以工商注册为准)
              </el-row>
            </el-col>
            <el-col :span="6" class="border value">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.compName }}</el-row
              >
            </el-col>
            <el-col :span="6" class="border label">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >企业地址</el-row
              >
            </el-col>
            <el-col :span="6" class="border value border-r">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.compAddr }}</el-row
              >
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div class="cus-blank"></div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="label border"
              >
                企业服务范围
              </el-row>
            </el-col>
            <el-col :span="6" class="border value">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.compRange }}</el-row
              >
            </el-col>
            <el-col :span="6" class="border label">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
              >
                是否为规上企业
              </el-row>
            </el-col>
            <el-col :span="6" class="border value border-r">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                v-if="applyInfo"
              >
                <div v-if="applyInfo.is_in_spec === 1">是</div>
                <div v-if="applyInfo.is_in_spec === 0">否</div>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div class="cus-blank"></div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="label border"
              >
                法定代表人
              </el-row>
            </el-col>
            <el-col :span="6" class="border value">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.legalName }}</el-row
              >
            </el-col>
            <el-col :span="6" class="border label">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
              >
                法定代表人<br />联系电话
              </el-row>
            </el-col>
            <el-col :span="6" class="border value border-r">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.legalTel }}</el-row
              >
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div class="cus-blank"></div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="label border"
              >
                企业本年度实际研<br />发费用(万元)
              </el-row>
            </el-col>
            <el-col :span="6" class="border value">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.fee }}</el-row
              >
            </el-col>
            <el-col :span="6" class="border label">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
              >
                申请奖补金额<br />(万元)
              </el-row>
            </el-col>
            <el-col :span="6" class="border value border-r">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.applyAmount }}</el-row
              >
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div class="cus-blank"></div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="label border"
                style="height: 200px"
              >
                企业简介
              </el-row>
            </el-col>
            <el-col
              :span="18"
              class="border value border-r height200"
              style="height: 200px"
            >
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
                >{{ applyInfo && applyInfo.compIntro }}</el-row
              >
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div :class="['cus-tab', { active: applyInfo && isDesignCenterEdit }]">工业设计中心联</div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                class="label height100"
                style="height: 200px"
                >南康工业(家具)<br />设计中心意见</el-row
              >
            </el-col>
            <el-col
              :span="18"
              class="border value text-area border-r"
              style="height: 200px"
            >
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
              >
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col v-if="!isDesignCenterEdit && applyInfo">
                      {{ applyInfo.result1 }}
                    </el-col>
                    <el-col v-if="applyInfo && isDesignCenterEdit">
                      <el-input
                        type="textarea"
                        :rows="8"
                        placeholder="请输入"
                        v-model="result1"
                        @change="getResult1"
                      ></el-input>
                    </el-col>
                    <el-col :span="10">
                      <el-row type="flex" align="middle">
                        <el-col v-show="!showDesignStamp"> ( 盖章 ) </el-col>
                        <el-col v-show="showDesignStamp">
                          <canvas
                            id="designStamp"
                            width="156"
                            height="156"
                          ></canvas>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div :class="['cus-tab', { active: applyInfo && isFurniturBureauEdit }]">家具产业促进局联</div>
          <el-row
            type="flex"
            class="apply-info-table-item flex-1"
            align="middle"
          >
            <el-col :span="6">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                class="label height100"
                >区家具产业促进局<br />意见</el-row
              >
            </el-col>
            <el-col
              :span="18"
              class="border value text-area border-r"
              style="height: 200px"
            >
              <el-row
                type="flex"
                justify="center"
                align="middle"
                class="border-content"
              >
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col v-if="!isFurniturBureauEdit && applyInfo">{{
                      applyInfo.result2
                    }}</el-col>
                    <el-col v-if="applyInfo && isFurniturBureauEdit">
                      <el-input
                        type="textarea"
                        :rows="8"
                        placeholder="请输入"
                        v-model="result2"
                        @change="getResult2"
                      ></el-input>
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col v-show="!showFurniturBureauStamp">
                          ( 盖章 )
                        </el-col>
                        <el-col v-show="showFurniturBureauStamp">
                          <canvas
                            id="furniturStamp"
                            width="156"
                            height="156"
                          ></canvas>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div class="flex">
          <div class="cus-blank"></div>
          <el-row class="apply-info-table-item flex-1">
            <el-col class="remark">
              备注:本表格用于首次申报南康工业（家具）设计奖补，同时报送公司营业执照复印件、法定代表人身份证复印件；与设计机构签订的设计合同；设计机构提供的全套设计图纸，包括效果图和三视图；提供由南康工业（家具）设计中心指定的所设计产品中的代表产品实物。
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col style="margin: 20px 0 0 40px; color: #6d7278"
        >本页面仅做视觉参考，真实有效章以下载PDF文件为准</el-col
      >
    </el-row>
  </div>
</template>
<script>
import { createSeal } from "@/utils";
export default {
  model: {
    prop: "applyInfo",
    event: "change",
  },
  props: {
    applyInfo: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showApplyCompStamp() {
      // 1 已保存待提交 12 已提交待申请者签约 2 审核中 3 审核不通过 4 审核通过 5 已签约 6 公示 7 发放奖补
      // 工厂 UI 章显示的时机: 设计中心签过合同以后会一直存在
      let flag = false;
      if (this.$route.query.type !== '12') {
        flag = true;
      }
      // if (["3", "4", "5", "6", "7"].includes(this.$route.query.type)) {
      //   flag = true;
      // } else if (this.$route.query.type === "2") {
      //   if (this.applyInfo) {
      //     if (this.applyInfo.approves.find((item) => item.code === "2501")) {
      //       if (
      //         this.applyInfo.approves.find((item) => item.code === "2501")
      //           .signedAt
      //       ) {
      //         flag = true;
      //       }
      //     }
      //   }
      // }
      return flag;
    },
    showDesignStamp() {
      let flag = false;
      if (this.applyInfo) {
        if (this.applyInfo.approves.length > 1) {
          flag = true;
        } else if (this.applyInfo.approves.length === 1) {
          if (this.applyInfo.approves[0].signedAt) {
            flag = true;
          }
        }
      }
      console.log("showDesignStamp", flag);
      return flag;
    },
    showFurniturBureauStamp() {
      let flag = false;
      if (this.applyInfo) {
        if (this.applyInfo.approves.length === 3) {
          if (
            this.applyInfo.approves.find((item) => item.code === "2503")
              .signedAt
          ) {
            flag = true;
          }
        }
      }
      return flag;
    },
    isDesignCenterEdit() {
      return (
        this.$store.state.loginUser.comp.comp_type === 9901 &&
        this.$route.query.type == "1"
      );
    },
    isFurniturBureauEdit() {
      return (
        this.$store.state.loginUser.comp.comp_type === 9903 &&
        this.$route.query.type == "1"
      );
    },
  },
  data() {
    return { result1: "", result2: "" };
  },
  mounted() {
    if (this.showApplyCompStamp) {
      createSeal(
        "applyComp",
        this.applyInfo.compName,
        this.applyInfo.id.slice(0, 16)
      );
    }
    if (this.showDesignStamp) {
      createSeal(
        "designStamp",
        "赣州市南康区居库商业管理有限公司",
        this.applyInfo.approves
          .find((item) => item.code === "2501")
          .id.slice(0, 16)
      );
    }
    if (this.showFurniturBureauStamp) {
      createSeal(
        "furniturStamp",
        "赣州市南康区家具产业促进局",
        this.applyInfo.approves
          .find((item) => item.code === "2503")
          .id.slice(0, 16)
      );
    }
  },
  methods: {
    getResult1() {
      this.applyInfo.result1 = this.result1;
    },
    getResult2() {
      this.applyInfo.result2 = this.result2;
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  width: 800px;
  margin: 26px auto 0;

  .apply-info {
    margin-top: 20px;
    padding: 0 20px 20px;
    // border: 1px solid #808080;

    .header {
      height: 200px;
      // padding: 0 20px;
      position: relative;

      .right {
        text-align: right;
      }
    }

    &-title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      color: #4d4d4d;
      // background: #e6e6e6;
    }
    &-table {
      width: 100%;
      text-align: center;

      .cus-tab {
        width: 40px;
        height: 170px;
        padding: 0 10px;
        background: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #d9d9d9;

        &.active {
          color: #409EFF;
        }
      }

      .cus-blank {
        width: 40px;
      }

      &-item {
        border-top: 1px solid #808080;

        .remark {
          font-size: 17px;
          text-align: left;
          border: 1px solid #808080;
          border-top: none;
          height: 80px;
        }
      }
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
    }

    .value {
      font-size: 14px;
      font-weight: 400;
      color: #808080;
      // min-width: 100px;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本溢出时显示省略标记*/
      display: -webkit-box; /*设置弹性盒模型*/
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical; /*子代元素垂直显示*/
    }

    .stamp {
      display: inline-block;
      position: absolute;
      width: 100px;
      left: 130px;
      top: 30px;
      border: 1px solid tomato;
      color: tomato;
      transform: skewY(-20deg);
    }

    .stamp1 {
      top: -10px;
      left: 40px;
    }

    .border {
      border-left: 1px solid #808080;
      height: 60px;
      &-content {
        height: 100%;
      }
      &.text-area {
        padding: 0 20px;
        height: 100px;
        border-left: 1px solid #808080;
      }
    }
  }
}
.height100 {
  height: 200px;
  border-left: 1px solid #808080;
  // line-height: 100px;
}

.border-r {
  border-right: 1px solid #808080;
}
</style>