var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.approveProcessInfo && _vm.approveProcessInfo.approves)?_c('el-row',[_c('div',{staticClass:"approve-process zt-block"},[_c('el-steps',{attrs:{"direction":"vertical","active":_vm.active,"space":60}},[_c('el-step',{attrs:{"icon":"iconfont icon-dggfg1"}},[_c('template',{slot:"title"},[_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',{attrs:{"span":4}},[_vm._v("南康区工业设计中心")]),(_vm.approve1)?_c('el-col',{attrs:{"span":2}},[(_vm.approve1.state === 1)?_c('span',[_vm._v("审批通过")]):_vm._e(),(_vm.approve1.state === 2)?_c('span',[_vm._v("审批不通过")]):_vm._e(),_c('br'),_c('span',{class:[
                  {
                    cause: true,
                    failure: _vm.approve1.state === 2,
                    success: _vm.approve1.state === 1,
                  },
                ]},[_vm._v(_vm._s(_vm.approve1.cause))])]):_vm._e(),(_vm.approve1)?_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm._f("moment")(_vm.approve1.approvedAt,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()],1)],1),_c('template',{slot:"description"},[(_vm.approve1 && _vm.approve1.detail)?_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',{attrs:{"span":5}},[_vm._v(" 指定代表产品实物: "),_c('span',{staticStyle:{"color":"#67c23a"}},[_vm._v(_vm._s(_vm.approve1.detail.item)+" ")])])],1):_vm._e()],1)],2),_c('el-step',{attrs:{"icon":"iconfont icon-dggfg1"}},[_c('template',{slot:"title"},[_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',{attrs:{"span":4}},[_vm._v("南康转企升规办")]),(_vm.approve2 && _vm.approve2.state === 1)?_c('el-col',{attrs:{"span":2}},[_vm._v("审批通过")]):_vm._e(),(_vm.approve2 && _vm.approve2.state === 2)?_c('el-col',{attrs:{"span":2}},[_vm._v("审批不通过")]):_vm._e(),(_vm.approve2)?_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm._f("moment")(_vm.approve2.approvedAt,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()],1)],1)],2),_c('el-step',{attrs:{"icon":"iconfont icon-dggfg1"}},[_c('template',{slot:"title"},[_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',{attrs:{"span":4}},[_vm._v("南康区家具产业促进局")]),(_vm.approve3)?_c('el-col',{attrs:{"span":2}},[(_vm.approve3.state === 1)?_c('span',[_vm._v("审批通过")]):_vm._e(),(_vm.approve3.state === 2)?_c('span',[_vm._v("审批不通过")]):_vm._e(),_c('br'),_c('span',{class:[
                  {
                    cause: true,
                    failure: _vm.approve3.state === 2,
                    success: _vm.approve3.state === 1,
                  },
                ]},[_vm._v(_vm._s(_vm.approve3.cause))])]):_vm._e(),(_vm.approve3)?_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm._f("moment")(_vm.approve3.approvedAt,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()],1)],1),_c('template',{slot:"description"},[(_vm.approve3 && _vm.approve3.detail)?_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',{attrs:{"span":5}},[_vm._v("审批金额(万元): "),_c('span',{staticStyle:{"color":"#67c23a"}},[_vm._v(" "+_vm._s(_vm.approve3.detail.amount)+" ")])])],1):_vm._e()],1)],2),_c('el-step',{attrs:{"title":"已归档","icon":"iconfont icon-dggfg1"}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }